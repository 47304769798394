/*!
 * Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

html {
	box-sizing: border-box;
	-ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

@import 'functions';
@import 'variables';

@import 'mixins/breakpoints';
@import 'mixins/grid-framework';
@import 'mixins/grid';

@import 'grid';
@import 'utilities/display';
@import 'utilities/flex';
@import 'utilities/spacing';
